import { createRouter, createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()

const router=createRouter({
    history: routerHistory,
    mode: 'history',
    // history:createWebHashHistory(),
    routes:[
        // {
        //     path:'/',
        //     name:"空白页",
        //     component:()=>import ('@/page/regisWebIndex/regisWebIndexPage.vue'),
        // },
        {
            path:'/',
            name:"空白页",
            component:()=>import ('@/page/entyPage.vue'),
        },
        {
            path:'/indexPage',
            name:"首页",
            component:()=>import ('@/page/indexPage.vue')
        },
        {
            path:'/login',
            name:'登录',
            component:()=>import ('@/page/loginPage.vue')
        },
        {
            path:'/platformServiceAgreement',
            name:'platformServiceAgreement',
            component:()=>import('@/page/platformServiceAgreement.vue')
        },
        {
            path:'/PrivacyPolicy',
            name:'PrivacyPolicy',
            component:()=>import('@/page/PrivacyPolicy.vue')
        },
        {
            path:'/regisPage',
            name:"个人注册界面",
            component:()=>import ('@/page/regisPage.vue')
        },
        {
            path:'/RegistrationEntryPage',
            name:'报名入口界面',
            component:()=>import('@/page/RegistrationEntryPage.vue')
        },
        {
            path:'/userOrdersPage',
            name:'报名历史记录',
            component:()=>import('@/page/userOrdersPage.vue')
        },
        {
            path:'/myCompetitonPage',
            name:'我的比赛',
            component:()=>import('@/page/myCompetitionPage.vue')
        },
        {
            path:'/myHonorPage',
            name:'我的荣誉界面',
            component:()=>import('@/page/myHonorPage.vue')
        },
        {
            path:'/entyPage',
            name:"空白页面",
            component:()=>import('@/page/entyPage.vue')
        },
        {
            path:'/adminIndexPage',
            name:"管理员",
            component:()=>import('@/page/admin/adminIndexPage.vue')
        },
        {
            path:'/techerPage',
            name:"老师管理界面",
            component:()=>import('@/page/techer/techerPage.vue')
        },
        {
            path:'/collegeRegisPage',
            name:'企业注册界面',
            component:()=>import('@/page/collegeRegisPage.vue')
        }

    ]
})

export default router